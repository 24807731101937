section#services {
    background-color: #3a3f42;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

section#services h2.content-header:after {
    background-color: #ffff;
}

section#services, section#services h2.content-header, section#services p {
    color: #ffff;
}

section#services p.title {
    font-weight: 600;
    min-height: 3em;
}

section#services p.info {
    font-weight: 300;
    font-size: 0.9em;
}

section#services img {
    max-height: 80px;
}

section#services a {
    color: #ffff;
    text-decoration: none !important;
    font-weight: 400;
}