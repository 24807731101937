section#team {
    background-color: #ecf0f5;
    z-index:-2;
}

section#team .main-container {
    padding-bottom: 10em;
    z-index: 2;
    position: relative;
}

@media (max-width: 800px) {
    section#team .main-container {
        padding-bottom: 1em;
    }
}

.team-member {
    background-color: rgb(44 147 213/80%) !important;
    color: #ffff;
    padding-left: 50px;
    margin-left: 60px;
    font-size: 0.9em;
}

/* .team-member:hover img {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
} */

.team-member img {
    position: absolute;
    left: 0px;
    top: 0;
    width: 120px;
    height: 117px;
    border-radius: 50%;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

.team-member span {
    display: block;
    margin-bottom: 5px;
}

.team-member .position {
    font-weight: 400;
    text-transform: uppercase;
    line-height: 100%;
    font-size: 0.8em;
}

.team-member .name {
    font-weight: 600;
    text-shadow: 1px 4px 6px rgb(53, 53, 53);
}

.team-member ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0.5em;
}

.team-member ul li {
    display: inline-block;
    padding: 5px;
    border: solid 1px #ffff;
    margin: 2px;
}

.team-member ul a {
    color: #fff;
}

.team-member ul a:hover {
    color: rgb(44 147 213);
}

.team-member ul a:hover li {
    background-color: #fff;
}
