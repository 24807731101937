section#missionvision {
    background-color: #3a3f42;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

section#missionvision h2.content-header:after {
    background-color: #ffff;
}

section#missionvision, section#missionvision h2.content-header {
    color: #ffff;
}

section#missionvision img {
    max-width: 140px;
    max-height: 120px;
}