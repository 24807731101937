footer {
    background: rgb(0, 179, 255);
    background: linear-gradient(90deg, rgba(0, 179, 255, 1) 0%, rgba(0, 241, 255, 1) 100%);
    color: #ffff;
}

footer h4:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 20%;
    transform: translateX(-50%);
    height: 3px;
    background: #ffff;
    transition: 0.35s;
}

footer h4 {
    position: relative;
    padding-bottom: 10px;
}

footer ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    padding-top: 10px;
    font-size: 0.9em;
    display: inline-block;
    text-align: left;
}

footer a {
    color: #ffff;
    text-decoration: none !important;
    font-weight: 300;
}

footer li {
    color: #ffff;
    font-weight: 300;
}

footer .nav-link {
    padding: 0;
}

footer img {
    max-height: 100px;
}

footer .copyright {
    font-weight: 300;
    font-size: 0.8em;
}