body {
  font-family: soleil, sans-serif !important;
}

section {
  padding-top: 40px;
  padding-bottom: 80px;
  color: #888888;
}

section h2.content-header {
  color: #3a3f42;
  padding-bottom: 8px;
}

section h2.content-header:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 5%;
  transform: translateX(-50%);
  height: 3px;
  background: #3a3f42;
  transition: 0.35s;
}

section div.content-info {
  margin-top: 30px;
}

section, footer {
  position: relative;
}

section a{
  word-wrap: break-word;
}

/* Scroll */

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

body::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

body::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Estilos track de scroll */

body::-webkit-scrollbar-track {
  background: #e1e1e1;
  border-radius: 10px;
}

body::-webkit-scrollbar-track:hover, body::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}
