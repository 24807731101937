section#testimonials .main-container {
    padding-bottom: 10em;
    z-index: 2;
    position: relative;
}

@media (max-width: 800px) {
    section#testimonials .main-container {
        padding-bottom: 1em;
    }
}

section#testimonials .owl-carousel .nav-btn {
    position: absolute;
    cursor: pointer;
    top: 100px !important;
    background-color: rgb(51, 59, 71, 0.3);
    color: #ffff;
    padding: 15px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    justify-content: center;
    align-items: center;
    display: none;
}

@media (min-width: 800px) {
    section#testimonials .owl-carousel .nav-btn {
        display: flex;
    }
}

section#testimonials .owl-carousel .owl-prev.disabled, section#testimonials .owl-carousel .owl-next.disabled {
    pointer-events: none;
    opacity: 0.2;
}

section#testimonials .owl-carousel .prev-slide {
    left: -40px;
}

section#testimonials .owl-carousel .next-slide {
    right: -40px;
}

section#testimonials .owl-carousel img {
    width: auto !important;
}

section#testimonials .show-more {
    background: #00b3ff;
    border: none;
    border-radius: 15px;
}

section#testimonials .owl-carousel .card {
    min-height: 500px;
}

section#testimonials .owl-carousel .card-subtitle {
    color: #00b3ff;
    font-weight: 300;
    font-size: 0.8em;
}

section#testimonials .card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-size: 0.7em;
}

.testimonialModal .modal-body .modal-col {
    display: flex;
    justify-content: center;
    align-items: center;
    word-spacing: -2px;
}

.testimonialModal .modal-body .subtitle {
    color: #00b3ff;
    font-weight: 600;
}

.testimonialModal .modal-footer button {
    background: #00b3ff;
    border: none;
    border-radius: 15px;
}
