.separator-top {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: -1;
    user-select:none;
}

.separator-top img {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
}
