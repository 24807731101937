nav a.navbar-brand {
  width: 300px;
  max-width: 70vw;
}

nav img {
  width: 100% \9;
  /* IE */
}

.button-menu {
  position: fixed;
  /* position: sticky; */
  top: 15px;
  right: 4%;
  height: 60px;
  width: 60px;
  cursor: pointer;
  z-index: 101;
}

/* Top bar with two lines */
@media (max-width: 714px) {
  .button-menu {
    top: 15px;
  }
}

.text-menu {
  color: #00b3ff;
  font-weight: 800;
  position: fixed;
  right: calc(4% + 40px + 20px);
  cursor: pointer;
}

.text-menu.scrolled {
  display: none;
}

@media (max-width: 700px) {
  .text-menu {
      display: none;
  }
}

.button-menu.scrolled {
  transition: all 0.3s ease 0s;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.button-menu.scrolled:hover {
  box-shadow: 0px 15px 20px rgba(0, 179, 255, 0.4);
  color: #fff;
  transform: translateY(-2px);
}

.button-menu.active .top {
  transform: translateY(10px) translateX(0) rotate(45deg);
  background: #00b3ff;
}

.button-menu.active .middle {
  opacity: 0;
  background: #00b3ff;
}

.button-menu.active .bottom {
  transform: translateY(-10px) translateX(0) rotate(-45deg);
  background: #00b3ff;
}

.button-menu span {
  background: #00b3ff;
  border: none;
  height: 5px;
  width: 30px;
  position: absolute;
  top: 18px;
  left: 15px;
  transition: all 0.35s ease;
  cursor: pointer;
}

.button-menu span:nth-of-type(2) {
  top: 28px;
}

.button-menu span:nth-of-type(3) {
  top: 38px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, width 0.35s;
  z-index: 100;
}

.overlay:before {
  content: "";
  background: #ffff;
  left: -55%;
  top: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  transition: left 0.35s ease;
}

.overlay:after {
  content: "";
  background: #ffff;
  right: -55%;
  top: 0;
  width: 50%;
  height: 100%;
  position: absolute;
  transition: all 0.35s ease;
}

.overlay.open {
  opacity: 0.95;
  visibility: visible;
  height: 100%;
}

.overlay.open:before {
  left: 0;
}

.overlay.open:after {
  right: 0;
}

.overlay.open li {
  -webkit-animation: fadeInRight 0.5s ease forwards;
  animation: fadeInRight 0.5s ease forwards;
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
}

.overlay.open li:nth-of-type(2) {
  -webkit-animation-delay: 0.45s;
  animation-delay: 0.45s;
}

.overlay.open li:nth-of-type(3) {
  -webkit-animation-delay: 0.55s;
  animation-delay: 0.55s;
}

.overlay.open li:nth-of-type(4) {
  -webkit-animation-delay: 0.65s;
  animation-delay: 0.65s;
}

.overlay.open li:nth-of-type(5) {
  -webkit-animation-delay: 0.75s;
  animation-delay: 0.75s;
}

.overlay nav {
  position: relative;
  height: 85%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  z-index: 100;
}

.overlay nav li:hover {
  font-weight: 600;
}

.overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}

.overlay ul li {
  display: block;
  height: 20%;
  height: calc(100% / 6);
  min-height: 50px;
  position: relative;
  opacity: 0;
}

.overlay ul li a {
  display: block;
  position: relative;
  color: #00b3ff;
  text-decoration: none;
  overflow: hidden;
  line-height: 0.9;
}

.overlay ul li a:hover:after, .overlay ul li a:focus:after, .overlay ul li a:active:after {
  width: 20%;
}

.overlay ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  transform: translateX(-50%);
  height: 3px;
  background: #00b3ff;
  transition: 0.35s;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
