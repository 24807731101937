#functionalities .card {
    margin-bottom: 0.5em;
    border-radius: 10px;
    border: 2px solid white;
}

#functionalities .card-header {
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    background: #ededec;
    color: #392a28;
    word-spacing: -1px !important;
}

#functionalities .card-header:before {
    content: "";
    width: 60px;
    height: 100%;
    border-right: 2px solid transparent;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

#functionalities .card-header:after {
    content: "";
    width: 60px;
    height: 100%;
    border-right: 2px solid transparent;
    border-radius: 10px;
    background: #dddddb;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

#functionalities .card-header .card-title {
    margin: 0;
    padding: 15px 40px 15px 80px;
    font-size: 18px;
    position: relative;
    cursor: pointer;
    z-index: 2;
}

#functionalities .card-header .card-title .toggle-arrow {
    font-weight: 700;
    font-weight: bold;
    position: absolute;
    left: 27px;
    top: 15px;
}

#functionalities .card-header .card-title>.fa-plus {
    font-size: 17px;
    line-height: 17px;
    display: block;
    position: absolute;
    right: 15px;
    top: 21px;
    width: 20px;
    height: 20px;
    text-align: center;
    color: #392a28;
}

#functionalities .card-header .collapse {
    padding: 0 40px 20px 80px;
    overflow: hidden;
}

#functionalities .card-header .collapsing {
    padding: 0 40px 20px 80px;
}

#functionalities .card-header .collapse .card-body {
    padding: 0;
    overflow: hidden;
}