section#last-articles {
  background-color: rgb(233, 237, 242, 0.3);
}

section#articles .separate-bottom {
  padding-bottom: 10em;
  z-index: 2;
  position: relative;
}

section#articles button.show-more, section#videos button.show-more {
  background: #00b3ff;
  border: none;
  border-radius: 15px;
}

.list-item {
  height: 300px;
}

.list-item .article {
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  text-decoration: none !important;
}

.list-item .article-content {
  font-size: 0.7em;
  width: 100%;
  text-align: left;
  padding-left: 5px;
  align-self: flex-end;
  background: linear-gradient(to bottom, 	transparent, 	#3a3f42);
}
.list-item .article-content span{
  margin-right: 15px;
  color: #fff;
  background-color: #00b3ff;
  padding: 2px 5px;
  -webkit-box-shadow: 0px 0px 17px -3px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 17px -3px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 17px -3px rgba(0,0,0,0.75);
}

.list-item .article-content h2{
  margin-top: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  color: #fff;
  font-weight: bold;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 1.5em;
}


/* ARTICLE */

.article-content p{
  color: #fff;
}

section#article .goback i {
  text-decoration: none;
  color: #888888;
}

section#article .tag {
  margin-left: 5px;
}

section#article .separator-margin {
  margin-bottom: 100px;
}

section#article .goback {
  font-size: 1.1em;
  text-decoration: none;
  color: #00b3ff;
}

section#article h2 {
  font-size: 2.8em;
  font-weight: 700;
  text-align: left;
  color: #000;  
  word-wrap: break-word;
  word-break: break-word;
}

section#article p,
section#article ul,
section#article ol,
section#article dl,
section#article h3 {
  color: #392a28;
}

section#article span {
  color: #00b3ff;
  font-weight: 300;
  font-size: 0.8em;
}

section#article p.post-date {
  width: 100%;
  text-align: right;
  border-bottom: 1.5px solid #888888;
  line-height: 0.6em;
  margin: 10px 0 20px;
}

section#article p.post-date span {
  background: #fff;
  padding: 0 10px;
  color: #888888;
}

.description{
  text-align: justify;
}

section#article  .fuente {
  text-align: center;
  font-style: italic;
  margin-top: 10px;
  align-items: self-end;
  display: grid;
  justify-items: end;
}

section#article .card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

section#article .card {
  flex: 1 1 calc(100% - 2rem);
  background: #f9f9f9;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
}

.card h4 {
  margin-top: 0;
  color: #333;
}

@media (min-width: 768px) {
  section#article .card-small { flex: 1 1 calc(48% - 2rem); }
}
@media (min-width: 1200px) {
  section#article .card-small { flex: 1 1 calc(32% - 2rem); }
}

@media (min-width: 1024px) {
  section#article .card-medium { flex: 1 1 calc(48% - 2rem); }
}


section#article .divTable {
  display: block;
  overflow:auto;
  border-collapse: collapse;
  color:#392a28
}

section#article .divTableBody {
  display:inline-table;
  width: 100%;
}

section#article .divTableRow {
  display: table-row;
}

section#article .divTableCell, section#article .divTableHead {
  display: table-cell;
  padding: 10px;
  border: 1px solid #999;
  text-align: left;
}

section#article .divTableHead {
  background-color: #EEE;
  font-weight: bold;
}

section#article .team-member span{
  color:#ffff;
  font-weight:600;
}




section#article .team-member .position{
    max-width: 80%;
    margin-right: 0;
    margin-left: auto;
}
