.carousel-caption {
    top: 0;
}

.carousel-caption span {
    display: block;
    font-size: 3.5vw;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 300;
    line-height: 110%;
    text-shadow: 1px 1px 20px #000000;
}

.carousel-caption span.caption-left {
    float: left;
    text-align: left;
}

.carousel-caption span.caption-right {
    float: right;
    text-align: left;
}

.carousel-caption span.caption-center {
    text-align: center;
}

.carousel-caption strong {
    font-weight: 600;
}

@media (max-width: 1000px) {
    .carousel-item img {
        width: auto !important;
        max-height: 300px;
    }
    .carousel-caption span {
        font-size: max(3.5vw, 2em);
    }
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

@-webkit-keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-200px);
        transform: translateX(-200px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-200px);
        -ms-transform: translateX(-200px);
        transform: translateX(-200px)
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0)
    }
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft
}